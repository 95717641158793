import React from "react";
import { IoIosLink } from "react-icons/io";
import { FiHeart, FiPhone } from "react-icons/fi";
import { Link } from "react-router-dom";

// Should create a function that calculates how many miles away the hotel is from the event.
function HotelsGrid({ hotels }) {
  return (
    <>
      {hotels.map((item, index) => {
        return (
          <div className="col-lg-4 column-td-6" key={index}>
            <div className="card-item">
              <a
                href={item.reservationLink}
                target="_blank"
                className="card-image-wrap"
                rel="noreferrer"
              >
                <div className="card-content-wrap">
                  <div className="card-content">
                    <Link to={item.titleUrl}>
                      <h4 className="card-title">
                        {item.name}
                        <i>{item.titleIcon}</i>
                      </h4>
                      <p className="card-sub">{item.title}</p>
                    </Link>
                    <ul className="info-list padding-top-20px">
                      <li>
                        <span className="la d-inline-block">
                          <FiPhone />
                        </span>{" "}
                        {item.phoneNumber}
                      </li>
                      <li>
                        {item.city + ", " + item.state + " " + item.zipCode}
                      </li>
                      <li>Rate: {item.rate}</li>
                      <li>Code: {item.code}</li>
                      <li>Cut-off Date: {item.cutoffDate}</li>
                    </ul>
                  </div>
                </div>
              </a>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default HotelsGrid;
