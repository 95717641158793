import React from "react";
import Copyright from "./Copyright";
import FooterLeftWidget from "./FooterLeftWidget";
import FooterQuickLinkWidget from "./FooterQuickLinkWidget";
import FooterContactWidget from "./FooterContactWidget";
import { useSelector } from "react-redux";

function Footer() {
  const footerData = useSelector((state) => state.footer);

  return (
    <>
      <section className="footer-area section-bg padding-top-140px padding-bottom-60px">
        <div className="box-icon"></div>
        <div className="box-icon"></div>
        <div className="box-icon"></div>
        <div className="box-icon"></div>
        <div className="box-icon"></div>
        <div className="box-icon"></div>
        <div className="container">
          <div className="row">
            <FooterLeftWidget footerleftcnts={footerData} />
            {/* <FooterQuickLinkWidget
              footerquicklink={footerData.footerquicklinks}
            /> */}
            {/* <FooterContactWidget footercontact={footerData.footercontact} /> */}
          </div>

          {/* Copyright */}
          <Copyright />
        </div>
      </section>
    </>
  );
}

export default Footer;
