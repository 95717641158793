import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Form, Field } from "react-final-form";
import DatePicker from "react-datepicker";
import { stateLocations } from "../../utils/StateLocations";
import reduxStore from "../../utils/firebase/";
import { addSingleEvent } from "../../redux/events";
import { withRouter, useHistory } from "react-router-dom";
import firebase from "../../utils/firebase/";

const initEvent = {
  name: "",
  date: "",
  location: {
    address: "",
    city: "",
    state: "",
    zipCode: null,
  },
  imageUrl: "",
  hotels: [],
};

const FormTitle = ({ title }) => (
  <div className="row">
    <div className="billing-title-wrap">
      <h3 className="widget-title pb-0">{title}</h3>
      <div className="title-shape margin-top-10px"></div>
    </div>
  </div>
);

const FieldDatePicker = ({ input, placeholder, minDate, maxDate }) => (
  <DatePicker
    className="form-control"
    dateFormat="yyyy-MM-dd"
    selected={input.value || null}
    onChange={input.onChange}
    minDate={minDate}
    maxDate={maxDate}
    disabledKeyboardNavigation
    placeholderText={placeholder}
  />
);

const FormSelect = (props) => {
  const { input, options } = props;

  return (
    <Select
      {...input}
      onChange={(value) => input.onChange(value)}
      onBlur={() => input.onBlur(input.value)}
      options={options}
    />
  );
};

const HotelForm = (hotel, index) => {
  const name = `hotel-name-${hotel.index}`;
  const address = `hotel-address-${hotel.index}`;
  const city = `hotel-city-${hotel.index}`;
  const zipCode = `hotel-zipCode-${hotel.index}`;
  const hotelState = `hotel-state-${hotel.index}`;
  const phoneNumber = `hotel-phoneNumber-${hotel.index}`;
  const breakfast = `hotel-breakfast-${hotel.index}`;
  const rate = `hotel-rate-${hotel.index}`;
  const code = `hotel-code-${hotel.index}`;
  const cutOffDate = `hotel-cutoff-${hotel.index}`;
  const resLink = `hotel-link-${hotel.index}`;
  const hotelTitle = `Hotel ${hotel.index + 1}`;

  return (
    <>
      <FormTitle title={hotelTitle} />
      <div className="row">
        <div className="col-lg-6">
          <div className="input-box">
            <label className="label-text">Name</label>
            <div className="form-group">
              <Field
                className="form-control"
                type="text"
                component="input"
                name={name}
                placeholder="Name"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="input-box">
            <label className="label-text">Address</label>
            <div className="form-group">
              <Field
                className="form-control"
                type="text"
                component="input"
                name={address}
                placeholder="Address"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="input-box">
            <label className="label-text">City</label>
            <div className="form-group">
              <Field
                className="form-control"
                type="text"
                component="input"
                name={city}
                placeholder="City"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="input-box">
            <label className="label-text">Zip Code</label>
            <div className="form-group">
              <Field
                className="form-control"
                type="text"
                component="input"
                name={zipCode}
                placeholder="Zip Code"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="input-box">
            <label className="label-text">State</label>
            <div className="form-group">
              <Field
                component={FormSelect}
                options={stateLocations}
                name={hotelState}
                placeholder="Select a State"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="input-box">
            <label className="label-text">Phone Number</label>
            <div className="form-group">
              <Field
                className="form-control"
                type="text"
                component="input"
                name={phoneNumber}
                placeholder="Phone Number"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="input-box">
            <label className="label-text">Rate</label>
            <div className="form-group">
              <Field
                className="form-control"
                type="text"
                component="input"
                name={rate}
                placeholder="Rate"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label className="label-text col-sm-8">Breakfast Included</label>
            <Field
              className="form-check-input"
              type="checkbox"
              component="input"
              name={breakfast}
              placeholder="Enter your hotel name"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="input-box">
            <label className="label-text">Code</label>
            <div className="form-group">
              <Field
                className="form-control"
                type="text"
                component="input"
                name={code}
                placeholder="Code"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="input-box">
            <label className="label-text">Cutoff Date</label>
            <div className="form-group">
              <Field component={FieldDatePicker} name={cutOffDate} />
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="input-box">
            <label className="label-text">Reservation Link</label>
            <div className="form-group">
              <Field
                className="form-control"
                type="text"
                component="input"
                name={resLink}
                placeholder="Reservation Link"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function GeneralInfo() {
  const [newEvent, setNewEvent] = useState(initEvent);
  const [startDate, setStartDate] = useState(new Date());
  const history = useHistory();

  useEffect(() => {
    return () => {};
  }, [newEvent]);

  const appendHotel = (event) => {
    var hotel = {
      name: "",
      address: "",
      city: "",
      state: "",
      zipCode: "",
      phoneNumber: "",
      breakfastIncluded: false,
      rate: "",
      code: "",
      cutoffDate: "",
      reservationLink: "",
    };

    newEvent.hotels.push(hotel);
    setNewEvent(newEvent);
  };

  const onSubmit = async (event) => {
    for (const [k, v] of Object.entries(event)) {
      if (k.match(/\d+$/)) {
        const keyArr = k.split("-");
        var index = k[k.length - 1];
        switch (keyArr[1]) {
          case "name":
            event.hotels[index].name = v;
            break;
          case "address":
            event.hotels[index].address = v;
            break;
          case "city":
            event.hotels[index].city = v;
            break;
          case "state":
            event.hotels[index].state = v.value;
            break;
          case "zipCode":
            event.hotels[index].zipCode = v;
            break;
          case "phoneNumber":
            event.hotels[index].phoneNumber = v;
            break;
          case "breakfast":
            event.hotels[index].breakfastIncluded = v;
            break;
          case "rate":
            event.hotels[index].rate = v;
            break;
          case "code":
            event.hotels[index].code = v;
            break;
          case "cutoff":
            event.hotels[
              index
            ].cutoffDate = firebase.firestore.Timestamp.fromDate(v);
            break;
          case "link":
            event.hotels[index].reservationLink = v;
            break;
          default:
            break;
        }
        delete event[`${k}`];
      }
    }

    // We have to make sure the state is actually set to a value and not a object before sending it to the server
    event.location.state = event.location.state.value;
    event.date = firebase.firestore.Timestamp.fromDate(event.date);
    event.imageUrl = "https://picsum.photos/200";

    //reduxStore.dispatch(addSingleEvent(event));
    history.push("/");
    console.log("Added new event");
  };

  return (
    <>
      <div className="billing-form-item">
        <FormTitle title="New Event" />
        <div className="billing-content">
          <div className="contact-form-action">
            <Form
              onSubmit={onSubmit}
              initialValues={newEvent}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="input-box">
                        <label className="label-text">Event Name</label>
                        <div className="form-group">
                          <Field
                            className="form-control"
                            type="text"
                            component="input"
                            name="name"
                            placeholder="Enter your event name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-box">
                        <label className="label-text d-flex align-items-center ">
                          Date
                        </label>
                        <div className="form-group">
                          <Field
                            component={FieldDatePicker}
                            name="date"
                            selected={startDate}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-box">
                        <label className="label-text d-flex align-items-center ">
                          Address
                        </label>
                        <div className="form-group">
                          <Field
                            className="form-control"
                            component="input"
                            type="text"
                            name="location.address"
                            placeholder="Enter the address of the event"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-box">
                        <label className="label-text">City</label>
                        <div className="form-group">
                          <Field
                            className="form-control"
                            type="text"
                            component="input"
                            name="location.city"
                            placeholder="City"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-box">
                        <label className="label-text">State</label>
                        <div className="form-group">
                          <Field
                            component={FormSelect}
                            options={stateLocations}
                            name="location.state"
                            placeholder="Select a State"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-box">
                        <label className="label-text">zipCode</label>
                        <div className="form-group">
                          <Field
                            className="form-control"
                            component="input"
                            name="location.zipCode"
                            placeholder="Zip Code"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {newEvent.hotels.map((hotel, index) => (
                    <HotelForm key={index} hotel={hotel} index={index} />
                  ))}
                  <div className="row">
                    <div className="col-lg-6">
                      <button type="submit" className="theme-btn border-0">
                        submit listing
                      </button>
                    </div>
                    <div className="col-lg-6">
                      <button
                        className="theme-btn border-0"
                        type="button"
                        onClick={() => appendHotel()}
                      >
                        Add Hotel
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default GeneralInfo;
