import { createSlice } from "@reduxjs/toolkit";

import {
  FaFacebookF,
  FaDribbble,
  FaBehance,
  FaLinkedinIn,
  FaRegEnvelope,
  FaTwitter,
} from "react-icons/fa";

import { AiOutlineHome } from "react-icons/ai";
import { FiHeadphones } from "react-icons/fi";
import darklogo from "../../assets/images/jamberlogo2.png";

const initialState = {
  footerlogo: darklogo,
  footerdesc: "Helping your team find the next hotel for your event!",
  sociallinks: [
    {
      icon: <FaFacebookF />,
      url: "https://facebook.com",
    },
    {
      icon: <FaTwitter />,
      url: "https://twitter.com",
    },
  ],
  footerquicklinks: {
    title: "Quick Links",
    links: [
      {
        path: "/about",
        text: "about us",
      },
      {
        path: "/sign-up",
        text: "sign up",
      },
      {
        path: "/login",
        text: "log in",
      },
      {
        path: "/add-listing/new",
        text: "add listing",
      },
      {
        path: "/contact",
        text: "contact us",
      },
      {
        path: "/pricing",
        text: "pricing",
      },
    ],
  },
  footercategories: {
    title: "Categories",
    links: [
      {
        path: "#",
        text: "Shops",
      },
      {
        path: "#",
        text: "Hotels",
      },
      {
        path: "#",
        text: "Restaurants",
      },
      {
        path: "#",
        text: "Bars",
      },
      {
        path: "#",
        text: "Events",
      },
      {
        path: "#",
        text: "Fitness",
      },
    ],
  },
  footercontact: {
    title: "Contact With Us",
    lists: [
      {
        icon: <FiHeadphones />,
        text: "+ 61 23 8093 3400",
      },
      {
        icon: <FaRegEnvelope />,
        text: "dirto@gmail.com",
      },
    ],
  },
  copyright: {
    menus: [
      {
        path: "#",
        title: "Terms & Conditions",
      },
      {
        path: "#",
        title: "Privacy Policy",
      },
      {
        path: "#",
        title: "Help Center",
      },
    ],
    languages: [
      "English",
      "Bangladesh",
      "Nepal",
      "America",
      "Arabic",
      "England",
    ],
  },
};

const footerSlice = createSlice({
  name: "footer",
  initialState,
  reducers: {},
});

export default footerSlice.reducer;
