import { Iterable } from "immutable";
import {
  configureStore,
  createSerializableStateInvariantMiddleware,
  isPlain,
} from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import eventsReducer from "../events";
import footerReducer from "../footer";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";

const isSerializable = (value) => Iterable.isIterable(value) || isPlain(value);

const getEntries = (value) =>
  Iterable.isIterable(value) ? value.entries() : Object.entries(value);

const serializableMiddleware = createSerializableStateInvariantMiddleware({
  isSerializable,
  getEntries,
});

const reduxStore = configureStore({
  reducer: {
    events: eventsReducer,
    footer: footerReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
  },
  middleware: [thunk],
});

export default reduxStore;
