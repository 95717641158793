import React, { useState } from "react";
import SignInOptions from "./SignInOptions";
import { AiOutlineUser } from "react-icons/ai";
import { FiLock } from "react-icons/fi";
import { Link } from "react-router-dom";

import { useFirebase } from "react-redux-firebase";
import { useHistory } from "react-router-dom";

function LoginBox({ title, subtitle, setFirebase }) {
  const firebase = useFirebase();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePwChange = (e) => {
    setPassword(e.target.value);
  };

  // Need to set up logic to update the state of the app if the login fails
  const signIn = (email, password) => {
    const creds = {
      email: email,
      password: password,
    };

    firebase
      .auth()
      .signInWithEmailAndPassword(creds.email, creds.password)
      .then((userCredential) => {
        var user = userCredential.user;
        history.push("/dashboard");
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
      });
  };

  return (
    <>
      <div className="billing-form-item mb-0">
        {/* <div className="billing-title-wrap border-bottom-0 pr-0 pl-0 pb-0 text-center">
          <h3 className="widget-title font-size-28 pb-0">{title}</h3>
          <p className="font-size-16 font-weight-medium">{subtitle}</p>
        </div> */}
        <div className="billing-content">
          <div className="contact-form-action">
            <form method="post">
              <div className="row">
                {/* <SignInOptions /> */}

                {/* <div className="col-lg-12">
                  <div className="account-assist mt-4 mb-4 text-center">
                    <p className="account__desc">or</p>
                  </div>
                </div> */}
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="label-text">Email</label>
                    <div className="form-group">
                      <span className="form-icon">
                        <AiOutlineUser />
                      </span>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        placeholder="Email"
                        onChange={handleEmailChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="input-box">
                    <label className="label-text">Password</label>
                    <div className="form-group">
                      <span className="form-icon">
                        <FiLock />
                      </span>
                      <input
                        className="form-control"
                        type="password"
                        name="password"
                        placeholder="Password"
                        onChange={handlePwChange}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-12">
                  <div className="form-group">
                    <div className="custom-checkbox mr-0 d-flex align-items-center justify-content-between">
                      <div>
                        <input type="checkbox" id="chb1" />
                        <label htmlFor="chb1">Remember Me</label>
                      </div>
                      <div>
                        <Link
                          to="/recover"
                          className="color-text font-weight-medium"
                        >
                          Forgot password?
                        </Link>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-12">
                  <div className="btn-box margin-top-20px margin-bottom-20px">
                    <button
                      className="theme-btn border-0"
                      type="submit"
                      onClick={(event) => {
                        event.preventDefault();
                        signIn(email, password);
                      }}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default LoginBox;
