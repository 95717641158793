import { createSlice } from "@reduxjs/toolkit";
import Firebase from "../../utils/firebase";
import { loadEvents, addEvent } from "./actions";
import blueprint from "../../assets/images/blueprint.png";

var initialState = [
  {
    id: 1,
    name: "She's Ballin BluePrint",
    date: {
      seconds: 1625374800,
      nanoseconds: 0,
    },
    location: {
      address: "",
      city: "San Antonio",
      state: "TX",
      zipCode: 77449,
    },
    imageUrl: blueprint,
    hotels: [
      {
        name: "Courtyard Marriott San Antonio Airport",
        address: "8615 Broadway St",
        city: "San Antonio",
        state: "TX",
        zipCode: "78217",
        phoneNumber: "(210)-828-7200",
        breakfastIncluded: true,
        rate: "$114.00",
        code: "She's Ballin Blueprint",
        cutoffDate: "June 25",
        reservationLink:
          "https://www.marriott.com/events/start.mi?id=1604834994051&key=GRP",
      },
      {
        name: "Fairfield Inn by Marriott",
        address: "88 NE Loop 410",
        city: "San Antonio",
        state: "TX",
        zipCode: "78216",
        phoneNumber: "(210)-530-9899",
        breakfastIncluded: true,
        rate: "$102.00",
        code: "She's Ballin Blueprint",
        cutoffDate: "June 11th",
        reservationLink:
          "https://www.marriott.com/events/start.mi?id=1612891425409&key=GRP",
      },
      {
        name: "Hilton Garden Inn San Antonio Airport",
        address: "8505 Broadway St",
        city: "San Antonio",
        state: "TX",
        zipCode: "78217",
        phoneNumber: "(210)-822-1554",
        breakfastIncluded: true,
        rate: "$101.00",
        code: "She's Ballin Blueprint",
        cutoffDate: "July 2nd",
        reservationLink:
          "http://hiltongardeninn.hilton.com/en/gi/groups/personalized/S/SATAAGI-SBJ-20210709/index.jhtml",
      },
      {
        name: "Springhill Suites",
        address: "514 NE Loop 410",
        city: "San Antonio",
        state: "TX",
        zipCode: "78216",
        phoneNumber: "(210)-804-2266",
        breakfastIncluded: true,
        rate: "$99.00",
        code: "She's Ballin",
        cutoffDate: "June 18th",
        reservationLink:
          "https://www.marriott.com/events/start.mi?id=1612461755468&key=GRP",
      },
      {
        name: "Hilton Garden Inn Live Oak",
        address: "8101 Pat Booker Road",
        city: "San Antonio",
        state: "TX",
        zipCode: "78233",
        phoneNumber: "(210)-412-5923",
        breakfastIncluded: false,
        rate: "$114.00",
        code: "She's Ballin",
        cutoffDate: "June 9th",
        reservationLink: "",
      },
      {
        name: "Homewood Suites",
        address: "8531 Broadway St",
        city: "San Antonio",
        state: "TX",
        zipCode: "78217",
        phoneNumber: "(210)-804-0100",
        breakfastIncluded: true,
        rate: "$101.00",
        code: "She's Ballin Blueprint",
        cutoffDate: "June 9th",
        reservationLink:
          "http://homewoodsuites.hilton.com/en/hw/groups/personalized/S/SATABHW-SBJ-20210709/index.jhtml",
      },
      {
        name: "Holiday Inn Express",
        address: "8204 North IH-35",
        city: "Windcrest",
        state: "TX",
        zipCode: "78239",
        phoneNumber: "(210)-654-3800",
        breakfastIncluded: true,
        rate: "$95.00",
        code: "She's Ballin",
        cutoffDate: "June 9th",
        reservationLink:
          "https://www.hiexpress.com/redirect?path=hd&brandCode=EX&localeCode=en&regionCode=1&hotelCode=SATNN&_PMID=99801505&GPC=SBE&cn=no&viewfullsite=true",
      },
    ],
  },
];

export const getEvents = (events) => {
  return async (dispatch) => {
    try {
      const events = [];
      const snapshot = await Firebase.firestore().collection("events").get();
      snapshot.forEach((doc) => events.push({ id: doc.id, ...doc.data() }));
      dispatch(loadEvents(events));
    } catch (e) {
      console.log(e);
    }
  };
};

export const addSingleEvent = (event) => {
  return async (dispatch) => {
    try {
      const res = await Firebase.firestore().collection("events").add(event);
      console.log("Successfully added event: " + res.id);
      dispatch(addEvent(event));
    } catch (e) {
      console.log(e);
    }
  };
};

export const eventSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    getEvents,
  },
});

export default function eventsReducer(state = initialState, action) {
  switch (action.type) {
    case "LOAD_EVENTS": {
      return [...state, ...action.payload];
    }
    case "ADD_EVENT": {
      return [...state, action.payload];
    }
    case "UPDATE_EVENT": {
      return [...state, action.payload];
    }
    default:
      return state;
  }
}
