import React, { useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

function InfoBox3({ infoitems }) {
  const toDateTime = (secs) => {
    var t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(secs);
    var dateTime = moment(t);
    return dateTime.format("ll");
  };

  return (
    <div className="row mt-5">
      {infoitems.map((item, index) => (
        <Link
          key={item.id}
          to={{
            pathname: `/event/${item.id}`,
            state: item,
          }}
        >
          <div className="col-lg-3 column-td-6 home-event-box" key={index}>
            <div className="icon-box icon-box-layout info-more-content">
              <img className="img-fluid" src={item.imageUrl} />
              <div className="info-content">
                <h4 className="info__title">{item.name}</h4>
                <p className="info__desc">{toDateTime(item.date.seconds)}</p>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default InfoBox3;
