import React, { useEffect, useState } from "react";
import GeneralHeader from "../components/common/GeneralHeader";
import BannerTwo from "../components/banner/banner2/BannerTwo";
import ScrollTopBtn from "../components/common/ScrollTopBtn";
import SectionsHeading from "../components/common/SectionsHeading";
import InfoBox3 from "../components/other/infoboxes/InfoBox3";
import NewsLetter from "../components/other/cta/NewsLetter";
import Footer from "../components/common/footer/Footer";
import sectiondata from "../store/store";
import { useSelector, connect } from "react-redux";
import Firebase from "../utils/firebase";
import { result } from "lodash";
import { selectAllEvents } from "../redux/events/actions";
import getEvents from "../redux/events";

const mapStateToProps = (state) => {
  return { events: state.events };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadEvents: () => dispatch(getEvents()),
  };
};

function Home() {
  const events = useSelector((state) => state.events);

  return (
    <main className="home-2">
      {/* Header */}
      <GeneralHeader />

      {/* Banner */}
      {/* <BannerTwo bgImg={sectiondata.herobanners.banner2.bgimage} /> */}

      {/* How It Work */}
      <section className="hiw-area padding-top-100px padding-bottom-80px after-none text-center">
        <div className="container">
          <div className="row section-title-width text-center">
            <SectionsHeading
              title={sectiondata.howitworks.hiw2.sectitle}
              desc={sectiondata.howitworks.hiw2.seccontent}
            />
          </div>
          <InfoBox3 infoitems={events} />
        </div>
      </section>

      <Footer />

      <ScrollTopBtn />
    </main>
  );
}

export default connect(mapStateToProps)(Home);
