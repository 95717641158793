import React, { useRef, useEffect } from "react";
import { useLocation, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";

// Pages
import Home from "./pages/Home";
import ListingGrid from "./pages/listings/ListingGrid";
import ListingDetails from "./pages/listings/ListingDetails";
import AddListing from "./pages/listings/AddListing";
import UserProfile from "./components/other/account/UserProfile";
import Dashboard from "./pages/dashboard/Dashboard";

import Login from "./pages/Login";
import Error from "./pages/Error";
import ProtectedRoute from "./utils/ProtectedRoute";
import EventGrid from "./pages/events/EventGrid";

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    document.body.classList.add("is-loaded");
    childRef.current.init();
  }, [location]);

  return (
    <>
      <ScrollReveal
        ref={childRef}
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute path="/listing-grid" component={ListingGrid} />
            <AppRoute path="/hotel-details" component={ListingDetails} />
            {/* <ProtectedRoute path="/add-listing/new"> */}
            <AppRoute path="/add-listing/new" component={AddListing} />
            {/* </ProtectedRoute> */}
            <ProtectedRoute path="/user-profile">
              <AppRoute component={UserProfile} />
            </ProtectedRoute>
            <AppRoute path="/event/:id" component={EventGrid} />
            <ProtectedRoute path="/dashboard">
              <AppRoute component={Dashboard} />
            </ProtectedRoute>
            <AppRoute path="/login" component={Login} />
            <AppRoute component={Error} />
          </Switch>
        )}
      />
    </>
  );
};

export default App;
