import React, { useState, useEffect } from "react";
import GeneralHeader from "../../components/common/GeneralHeader";
import Breadcrumb from "../../components/common/Breadcrumb";
import HotelsGrid from "../../components/places/HotelsGrid";
import Footer from "../../components/common/footer/Footer";
import ScrollTopBtn from "../../components/common/ScrollTopBtn";
import breadcrumbimg from "../../assets/images/bread-bg.jpg";
import Spinner from "../../components/Spinner";

import { useParams } from "react-router-dom";

const state = {
  bdimg: breadcrumbimg,
};
export const EventGrid = (props) => {
  const { id } = useParams();
  const [event, setEvent] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setEvent(props.location.state);
    setLoading(false);
  }, [id]);

  return loading ? (
    <Spinner />
  ) : (
    <main className="listing-grid">
      {/* Header */}
      <GeneralHeader />

      {event && (
        <Breadcrumb
          CurrentPgTitle={event.name}
          MenuPgTitle="Hotels"
          img={event.imageUrl}
        />
      )}

      {/* Place Grid --> Place for the hotels */}
      <section className="card-area padding-top-40px padding-bottom-100px">
        <div className="container">
          {event && (
            <div className="row">
              <HotelsGrid hotels={event.hotels} />
            </div>
          )}
        </div>
      </section>

      <Footer />

      <ScrollTopBtn />
    </main>
  );
};

export default EventGrid;
