import React from "react";
import { useSelector } from "react-redux";
import CopyrightMenu from "./CopyrightMenu";
import { FiHeart } from "react-icons/fi";

function Copyright() {
  const footerData = useSelector((state) => state.footer);
  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="copy-right margin-top-50px padding-top-60px">
            <p className="copy__desc">
              &copy; Copyright Jamber Hotels 2021. Made with
              <span className="la">
                {" "}
                <FiHeart />{" "}
              </span>{" "}
              by <a href="">Other People's Computer</a>
            </p>

            {/* <CopyrightMenu copyrightright={footerData.copyright} /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Copyright;
