import { LOAD_EVENTS, ADD_EVENT, UPDATE_EVENT } from "./types";

export const loadEvents = (events) => ({
  type: LOAD_EVENTS,
  payload: [...events],
});

export const addEvent = (event) => ({
  type: ADD_EVENT,
  payload: event,
});

export const updateEvent = (event) => ({
  type: UPDATE_EVENT,
  payload: event,
});
